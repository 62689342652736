// **  Initial State
// const initialState = {
//   userData: user.userData
// }
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { userData: user.userData }
  : {
      userData: {
        user_permission: [],
      },
    };
const authReducer = (state = initialState, action) => {
  if (!user?.userData?.user_permission)
    initialState.userData.user_permission = [];
  let stateSet = {
    ...state,
  };
  if (action.type == "setPermission") {
    state.userData.user_permission = action.data;
  }

  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]:
          action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]:
          action[action.config.storageRefreshTokenKeyName],
      };
    case "LOGOUT":
      const obj = { ...action };
      delete obj.type;
      return { ...state, userData: {}, ...obj };
    case "setPermission":
      return {
        ...stateSet
      };
    default:
      return state;
  }
};

export default authReducer;
